// Here you can add other styles
/*body, .c-default-layout{
    background-color: #E3F2FD;
}*/
.bg-lightblue{
    background-color: #f0f6fa;
}
.blockuiloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    .loader-element {

        @include transition(spin .3s linear infinite);
        -webkit-animation:spin 3s linear infinite;
        -moz-animation:spin 3s linear infinite;
        animation:spin 3s linear infinite;
       /* @include transition(bounce .3s linear infinite);
        -webkit-animation:bounce 1s linear infinite;
        -moz-animation:bounce 1s linear infinite;
        animation:bounce 1s linear infinite;*/
    }

    &.show {
        height: 0;

        .loader-element {
            display: none !important;
        }
    }
}
.section {
    position: relative;
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
}

.section-header {
    position: relative;
    padding-top: $spacer * 12;
    padding-bottom: $spacer * 12;
}
.overlay-background {
    background: #111;
    position: relative;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index:10;
  }
  .overlay_text{
    z-index:20;

  }
  /*.avatar {
    vertical-align: middle;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }*/
  .hover_zoom_effect{
    transform: scale(100%);
    transition: transform 1s;
  }
  .hover_zoom_effect:hover{
    transform: scale(105%);
    transition: transform 1s;
  }
  .file-field input[type="file"] {
    max-width: 230px;
    position: absolute;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    padding-bottom: 30px;
}
.file-field span {
    cursor: pointer;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
